import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = [
    {
        // will match everything
        path: '*',
        component: () => import('../views/404.vue'),
    },
    {
        path: '/',
        name: 'Home',
        redirect: '/website',
    },
    // {
    // 	path: '/dashboard',
    // 	name: 'Dashboard',
    // 	layout: "dashboard",
    // 	component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/index.vue'),
    // },
    // {
    // 	path: '/layout',
    // 	name: 'Layout',
    // 	layout: "dashboard",
    // 	component: () => import('../views/Layout.vue'),
    // },
    {
        path: '/website',
        name: 'Website',
        layout: "dashboard",
        component: () => import('../views/website/index.vue'),
        meta: {
            label: '站点管理'
        }
    },
    {
        path: '/column',
        name: 'Column',
        layout: "dashboard",
        component: () => import('../views/column/index.vue'),
        meta: {
            label: '栏目管理'
        }
    },
    {
        path: '/article',
        name: 'Article',
        layout: "dashboard",
        component: () => import('../views/article/index.vue'),
        meta: {
            label: '文章管理'
        }
    },
    {
        path: '/dictionary',
        name: 'Dictionary',
        layout: "dashboard",
        component: () => import('../views/dictionary/index.vue'),
        meta: {
            label: '业务字典'
        }
    },
    {
        path: '/link',
        name: 'Link',
        layout: "dashboard",
        component: () => import('../views/link/index.vue'),
        meta: {
            label: '链接管理'
        }
    },
    {
        path: '/tables',
        name: 'Tables',
        layout: "dashboard",
        component: () => import('../views/Tables.vue'),
    },
    {
        path: '/billing',
        name: 'Billing',
        layout: "dashboard",
        component: () => import('../views/Billing.vue'),
    },
    {
        path: '/rtl',
        name: 'RTL',
        layout: "dashboard-rtl",
        meta: {
            layoutClass: 'dashboard-rtl',
        },
        component: () => import('../views/RTL.vue'),
    },
    {
        path: '/Profile',
        name: 'Profile',
        layout: "dashboard",
        meta: {
            layoutClass: 'layout-profile',
        },
        component: () => import('../views/Dashboard.vue'),
    },
    {
        path: '/sign-in',
        name: 'Sign-In',
        component: () => import('../views/auth/sign-in.vue'),
    },
    {
        path: '/sign-up',
        name: 'Sign-Up',
        meta: {
            layoutClass: 'layout-sign-up',
        },
        component: () => import('../views/Sign-Up.vue'),
    },
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
    route.meta = route.meta || {};
    route.meta.layout = route.layout || parentLayout;

    if (route.children) {
        route.children = route.children.map((childRoute) => addLayoutToRoute(childRoute, route.meta.layout));
    }
    return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        }
        return {
            x: 0,
            y: 0,
            behavior: 'smooth',
        }
    }
})

export default router
