// import Config from '@/config';
// import { getLocalBank } from '@/utils/storage'
import _, {isEmpty} from 'lodash'
// import ExceptionHandler from '@/api/exceptionHandler'
// import { useUserStore } from '@/store/profile'
import store from '@/store'

export default async (url = '', data = {}, type = 'GET', method = 'fetch') => {
  type = type.toUpperCase()
  if (['GET', 'DELETE'].includes(type) && !_.isEmpty(data)) {

    if(url.includes('{')) {
      url = url.replace(/{.+?}/g, (match) => {
        const key = match.substring(1, match.length - 1)
        return data[key]
      })
    } else {
      let dataStr = '' // 数据拼接字符串
      Object.keys(data).forEach((key) => {
        dataStr += `${key}=${data[key]}&`
      })

      if (dataStr !== '') {
        dataStr = dataStr.substr(0, dataStr.lastIndexOf('&'))
        url = `${url}?${dataStr}`
      }
    }
  }

  if (method === 'fetch' && window.fetch) {
    // let localUserInfo = sessionStorage.getItem('PC_USER')
    // let Authorization = ''
    // try {
    //   localUserInfo = JSON.parse(localUserInfo)
    //   Authorization = localUserInfo.token
    // } catch (e) {
    //   console.log(e)
    // }

    const headers = {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
      // Authorization: url.includes('open') ? '' : Authorization
    }

    const token = store.getters['auth/token']
    if(!isEmpty(token)) {
      headers['Authorization'] = `Bearer ${token}`
    }

    const requestConfig = {
      credentials: 'include', // 为了在当前域名内自动发送 cookie ， 必须提供这个选项
      method: type,
      headers,
      mode: 'cors' // 请求的模式
      // cache: "force-cache"
    }

    if (type === 'POST') {
      Object.defineProperty(requestConfig, 'body', {
        value: JSON.stringify(data)
      })
    }

    try {
      const response = await fetch(url, requestConfig)
      const responseJson = await response.json()
      const { success, code, message } = responseJson
      if (!success) {
        // ExceptionHandler({ code, message })
      }
      return responseJson
    } catch (error) {
      throw new Error(error)
    }
  } else {
    // return new Promise((resolve, reject) => {
    //   let requestObj;
    //   if (window.XMLHttpRequest) {
    //     requestObj = new XMLHttpRequest();
    //   } else {
    //     // requestObj = new ActiveXObject;
    //   }
    //
    //   let sendData = '';
    //   if (type === 'POST') {
    //     sendData = JSON.stringify(data);
    //   }
    //
    //   requestObj.open(type, url, true);
    //   requestObj.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    //   requestObj.send(sendData);
    //
    //   requestObj.onreadystatechange = () => {
    //     if (requestObj.readyState === 4) {
    //       if (requestObj.status === 200) {
    //         let obj = requestObj.response
    //         if (typeof obj !== 'object') {
    //           obj = JSON.parse(obj);
    //         }
    //         resolve(obj)
    //       } else {
    //         reject(requestObj)
    //       }
    //     }
    //   }
    // })
  }
}
