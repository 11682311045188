import API from '../index'

const MODULE = 'cms/auth'

const URLS = {
  login: `${process.env.VUE_APP_BASE_API}/${MODULE}/login`,
  register: `${process.env.VUE_APP_BASE_API}/${MODULE}/register`,
}

export const login = (params) => {
  return API(URLS.login, params, 'POST');
}

export const register = (params) => {
  return API(URLS.register, params, 'POST');
}
