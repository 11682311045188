import API from '../index'
import {CRUDBuilder} from "@/api/util";

const MODULE = 'cms/website'

const URLS = {
  ...CRUDBuilder(MODULE),
}

export const save = (params) => {
  return API(URLS.save, params, 'POST');
}

export const query = (params) => {
  return API(URLS.query, params, 'POST');
}

export const detail = (params) => {
  return API(URLS.detail, params, 'GET');
}

export const remove = (params) => {
  return API(URLS.remove, params, 'DELETE');
}
