import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

import auth from "@/store/module/auth";
import selection from "@/store/module/selection";

const store = new Vuex.Store({
  modules: {
    auth,
    selection,
  },
  plugins: [
    createPersistedState({
      key: 'yunbiao-client',
      paths: ['auth']
    })
  ]
})

export default store
