import {login} from "@/api/service/auth";

export default {
  namespaced: true,
  state: {
    token: '',
    user: {},
  },
  getters: {
    token: state => state.token,
    user: state => state.user
  },
  mutations: {
    ['SET_TOKENS'] (state, token) {
      state.token = token
    },
    ['SET_USER'] (state, data) {
      state.user = data
    },
  },
  actions: {
    async signIn({commit}, params) {
      const result = await login(params)
      const { success, data, extend } = result
      if(success && data) {
        commit('SET_TOKENS', data)
        commit('SET_USER', extend)
      }
      return result
    },
  }
}
