import {query as websiteQuery} from "@/api/service/website";
import {isEmpty} from "lodash";
import dayjs from "dayjs";
import {tree as columnTree } from "@/api/service/column";
import {tree as linkTree } from "@/api/service/link";

function getExpired(expire, expireTime = 10) {
  return expire === 0 || dayjs().diff(dayjs(expire), 'm') > expireTime
}

const allPagination = {
  pageNumber: 1,
  pageSize: 999,
}

export default {
  namespaced: true,
  state: {
    websiteOptions: [],
    websiteExpire: 0,
    columnOptions: [],
    columnExpire: 0,
    linkOptions: [],
    linkExpire: 0,
  },
  getters: {
    websiteOptions: state => state.websiteOptions,
    columnOptions: state => state.columnOptions,
    linkOptions: state => state.linkOptions,
  },
  mutations: {
    ['SET_WEBSITE'] (state, data) {
      state.websiteOptions = data.content.map(item => ({...item, label: item.name, value: item.id}))
      state.websiteExpire = data.expire
    },
    ['SET_COLUMN'] (state, data) {
      state.columnOptions = data.content
      state.columnExpire = data.expire
    },
    ['SET_LINK'] (state, data) {
      state.linkOptions = data.content
      state.linkExpire = data.expire
    },
  },
  actions: {
    async loadWebsiteOptions({state, commit}, params) {
      const empty = isEmpty(state.websiteOptions)
      const expired = getExpired(state.websiteExpire)
      if(empty || expired) {
        const { success, data: { content } } = await websiteQuery({
          ...params,
          pagination: allPagination
        })
        if(success) {
          commit('SET_WEBSITE', {
            content,
            expire: dayjs()
          })
        }
      }
    },
    async loadColumnOptions({state, commit}, params) {
      const empty = isEmpty(state.columnOptions)
      const expired = getExpired(state.columnExpire)
      if(empty || expired) {
        const { success, data: { nodes } } = await columnTree({
          ...params,
        })
        if(success) {
          commit('SET_COLUMN', {
            content: nodes,
            expire: dayjs()
          })
        }
      }
    },
    async loadLinkOptions({state, commit}, params) {
      const empty = isEmpty(state.linkOptions)
      const expired = getExpired(state.linkExpire)
      if(empty || expired) {
        const { success, data: { nodes } } = await linkTree({
          ...params,
        })
        if(success) {
          commit('SET_LINK', {
            content: nodes,
            expire: dayjs()
          })
        }
      }
    },
  }
}
