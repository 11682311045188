import API from '../index'
import {CRUDBuilder} from "@/api/util";

const MODULE = 'cms/hyperlink'

const URLS = {
  ...CRUDBuilder(MODULE),
  tree: `${process.env.VUE_APP_BASE_API}/${MODULE}/tree`,
}

export const save = (params) => {
  return API(URLS.save, params, 'POST');
}

export const query = (params) => {
  return API(URLS.query, params, 'POST');
}

export const detail = (params) => {
  return API(URLS.detail, params, 'GET');
}

export const remove = (params) => {
  return API(URLS.remove, params, 'DELETE');
}

export const tree = (params) => {
  return API(URLS.tree, params, 'GET');
}
